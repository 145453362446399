
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





















































.podcasts,
[class*='podcasts--'] {
  // overflow: hidden;

  ::v-deep .podcasts__intro__links {
    margin-top: $spacing * 2;
  }

  ::v-deep .card__infos {
    .wysiwyg {
      margin-bottom: $spacing * 2;
    }

    .podcasts__intro__links {
      margin-top: $spacing * 0.5;
    }
  }
}

.podcasts__hero {
  margin-top: $spacing * 2.5;

  @include mq(m) {
    margin-top: $spacing * 4;
  }

  @include mq(l) {
    margin-top: $spacing * 5;

    ::v-deep .hub-net-mbps__speedlines-outer {
      top: 0;
    }
  }

  @include mq(xxl) {
    margin-top: $spacing * 8;
  }
}

.podcasts__intro__link {
  display: block;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  @include mq(s) {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}

.podcasts__intro__link__badge {
  max-height: 5rem;

  @include mq(s) {
    max-height: 4rem;
  }
}

.podcasts__intro__all {
  margin-top: $spacing * 0.5;
}

.podcasts__cards {
  ::v-deep {
    .card__picture-outer {
      display: flex;
      align-items: center;
      padding: $spacing;

      &::before {
        display: none;
      }
    }

    .card__picture {
      position: static;
      height: auto;
    }
  }
}
